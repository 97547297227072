<script setup lang="ts">
import type { AsfContentPageProps } from '@ui/types'
import { AsfWrapper } from '#components'

const props = withDefaults(defineProps<AsfContentPageProps>(), { loading: true })

const isOpened = ref(false)
const { isMediaMatch: isSmallOrMediumView } = useMediaQuery()

const isHomepage = computed(() => props.appearance === 'homepage')
const isNavigation = computed(() => props.appearance === 'navigation')

const toggleMenu = () => {
  isOpened.value = !isOpened.value
}
</script>
<template>
  <component
    :is="isHomepage ? 'div' : AsfWrapper"
    :class="['asf-content-page asf-page-bottom-indent', { 'asf-page-top-indent': !isNavigation }]"
  >
    <AsfSkeleton
      v-if="!isHomepage"
      :loading="loading || !$slots['content-breadcrumbs']"
      :size="['full', '4']"
      class="asf-content-page__breadcrumbs"
    >
      <slot name="content-breadcrumbs" />
    </AsfSkeleton>
    <AsfSkeleton
      v-if="isNavigation"
      v-show="isSmallOrMediumView"
      :loading="loading || !$slots['content-button']"
      :size="['full', '12']"
      class="asf-content-page__aside-button"
    >
      <slot name="content-button" :toggle-menu="toggleMenu" />
    </AsfSkeleton>
    <AsfSidebar v-if="isNavigation && isSmallOrMediumView" :visible="isOpened" @sidebar:close="toggleMenu">
      <div class="px-4">
        <slot name="content-aside" />
        <slot name="content-info" />
      </div>
    </AsfSidebar>
    <aside v-if="isNavigation && !isSmallOrMediumView" class="asf-content-page__aside">
      <AsfSkeleton :loading="loading || !$slots['content-aside']" :size="['full', '80']">
        <slot name="content-aside" />
      </AsfSkeleton>
      <AsfSkeleton :loading="loading || !$slots['content-info']" :size="['full', '52']">
        <slot name="content-info" />
      </AsfSkeleton>
    </aside>
    <main role="main" class="asf-content-page__content">
      <AsfSkeleton
        v-if="!isHomepage"
        :loading="loading || !$slots['content-title']"
        :size="['full', '14']"
        class="asf-content-page__title"
      >
        <slot name="content-title" />
      </AsfSkeleton>
      <slot v-else name="content-title" />
      <AsfSkeleton
        :loading="loading || !$slots['content-main']"
        :size="['full', '96']"
        :class="{ 'asf-content': !isHomepage }"
      >
        <slot name="content-main" />
      </AsfSkeleton>
    </main>
  </component>
</template>
<style lang="postcss">
@import '@components/templates/ContentPage/ContentPage.css';
</style>
